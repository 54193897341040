.sidebar-container {
  display: flex;
  flex-direction: column;
  height: calc(100% - 50px);

}

.nav-orb {
  margin-bottom: 10px;
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  text-align: center; /* Center text alignment for multi-line text */
  background: rgba(69, 246, 113, 0.7);
  backdrop-filter: blur(10px);
  border-radius: 50px;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.19), 0 5px 5px rgba(0, 0, 0, 0.23); /* Add shadow for raised effect */
}


.dragdrop {
  width: 100%;       /* Take up 100% of parent's width */
  height: 100%;      /* Take up 100% of parent's height */
  display: flex;     /* Use flexbox to center content, if needed */
  justify-content: center; /* Center horizontally, if needed */
  align-items: center; /* Center vertically, if needed */
}


  
.user-data-panel {
  background-color: lightblue;
  width: 100%;
  flex-grow: 1; /* Expand to fill remaining space */
}
