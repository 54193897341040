/* NotificationsComponent.css */

.notification-container {
    display: flex;            /* Use Flexbox */
    justify-content: center;  /* Center horizontally */
    align-items: center;      /* Center vertically */
    padding: 5px;
    margin: 5px;
    font-size: 1rem;      /* Standard font size */
    text-align: center;   /* Center the text. This is more for inner inline or inline-block elements */
    max-width: 300px;     /* Maximum width of the notification */
    word-wrap: break-word;/* Ensure long words do not overflow */
    height: 50px;         /* Specify a height for the container */
}