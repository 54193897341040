@import url('non.geist/mono');
@import url('non.geist');

#app{
  /* background-color: rgb(33, 81, 244); */
  /* background-color: var(--primary-color-dark-mode); */
  background-color: #F1EFED;
}

body {
  font-family: 'Geist Variable';
}
:root {
  --base-unit: max(.5px, 1/1800 * 100vw);
  --border-radius: calc(var(--base-unit) * 32);
  --primary-color-dark-mode: rgba(25, 26, 35, 1);
  --secondary-color-dark-mode: rgb(133, 134, 153);
  --text-color-dark-mode: #D9DDDC;
  --highlight-color: rgb(87, 91, 199);
  --highlight-color-2-dark-mode: rgb(76, 77, 98);
}
.shared-url-container{
  width: '500px';
  height: '80px';
  position: 'fixed';
  background-color: 'rgba(38, 39, 53, 1)';
  box-shadow: '0px 4px 6px rgba(0, 0, 0, 0.1)';
  z-index: 10000;
  border-radius: '5px';
  border: '1px solid white';
  display: 'block';
  left: '250px';
  top: '250px';
  padding: "4px";
}

.dashboard-container {
  /* position: relative; */
  /* display: flex; */
  /* flex-direction: row; */
  /* background-color: rgb(33, 81, 244); */
  background-color: #ffffff;
  /* padding: 12px; */
  height: 100vh; /* Full viewport height */
  box-sizing: border-box; 
  background-image: url(https://arc.net/noise-light.png);
  background-repeat: repeat; /* Adjust as needed */
}

.database-list{
  height: 50%;
  min-height: 300px;
  /* background-color: red; */
  width: 100%;
  overflow-y: scroll;
}

.left-component {
  position: relative;
  /* left: 0; */
  width: 180px;
  min-width: 180px;
  height: 100vh;
  /* height: 100%; */
  /* top: 0; */
  background-color: var(--primary-color-dark-mode);
  display: flex;
  flex-direction: column;
  padding-left: 12px;
  /* padding-right: 12px; */
  padding-top: 10px;
  border-right: 1px solid rgba(39, 41, 56, 1); /* Add a thin black border to the right */
  /* overflow-x: hidden; */
  /* background: purple; */
  /* overflow-y: scroll; */
}

.left-extension-files {
  /* position: relative;  I DONT KNOW WHY THIS WORKS IN ALLOWING THE POP UP*/
  /* left: 180px; */
  width: 200px;
  height: 100vh;
  /* top: 0; */
  background-color: var(--primary-color-dark-mode);
  display: flex;
  flex-direction: column;
  /* padding-left: 12px; */
  padding-right: 6px;
  padding-top: 10px;

}

.left-extension {
  display: flex;
  flex-direction: row;
  height: 100vh;
  width: auto;
}

.invisible-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: transparent;
  z-index: 9999; /* Adjust if needed to be below the settings div but above everything else */
  pointer-events: none; /* Allows clicks to pass through */
}
.files {
  display: flex;
  flex-direction: row;
  /* border-radius: 5px; */
  /* padding-left: 20px; */
  height: 30px;
  padding-left: 6px;
  gap: 10px;
}
.files-container{
  overflow-y: scroll;

}

.files span { 
  color: rgb(210, 211, 224);
  /* line-height: 30px; */
  position: relative;
  font-size: 11px;
  /* left: 20px; */
}
.file-div {
  border-radius: 5px;
  padding-left: 10px;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
}
.file-name-container {
  flex: 1; /* Allows the container to expand taking up available space */
  white-space: nowrap; /* Prevents text wrapping */
  overflow: hidden; /* Hide overflow content */
  text-overflow: ellipsis; /* Add ellipsis when text overflows */
  margin-right: 2px; /* Ensures spacing between the name and the meta info */
  font-family: 'Geist Variable';

}
.file-meta-div {
  display: flex;
  flex-direction: row;
  gap: 5px;
  padding-left: 5px;
  padding-right: 5px;
  align-items: center; /* Ensures alignment of the contents within */
  width: 80px;
  /* background-color: red; */
  justify-content: space-evenly;
  min-width: fit-content; /* This ensures it takes space as needed without being squished */

}
.seq-num, .protein-count { /* Styles applied to both divs for consistency */
  border-radius: 5px; /* Increased border-radius for a more "pill-like" shape */
  padding: 2px 5px;
  height: 50%;
  display: flex;
  align-items: center; /* Center the text vertically */
  justify-content: center; /* Center the text horizontally */
  /* font-family: 'JetBrains Mono', monospace; */
  font-family: 'Geist Mono Variable';

}
.seq-num {
  background-color: #FF6347; /* Tomato color for sequence length */
  background-color: hsla(32, 100%, 10%, 1);
  background-color: hsla(214, 59%, 15%, 1);
}
.seq-num span{
  color: hsla(35, 100%, 52%, 1);
  color: hsla(210, 100%, 66%, 1);
}
.protein-count {
  background-color: #4682B4; /* SteelBlue color for the number of proteins */
  background-color: hsla(171, 75%, 12%, 1);
}
.protein-count span{
  color: hsla(171, 90%, 41%, 1);
}
.no-files-message{
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  text-align: center;
  height: 100%;
  color: var(--text-color-dark-mode);
}
  .right-component {
    position: relative;
    display: flex;
    flex-direction: column;
    /* width: 400px; */
    flex-grow: 1;
    padding-left: 10px; /*  should be width of SlidingHandle */
    /* padding-right: 10px; */
    /* right: 0px; */
    /* top: 0px; */
    /* left: 400px; */
    height: 100vh; 
    background-color: var(--primary-color-dark-mode);
  }

.info-panel {
  display: flex;
  flex-direction: row;
  /* position: relative; */
  background-color: var(--primary-color-dark-mode);
  border-left: 1px solid rgba(39, 41, 56, 1);
  /* overflow: auto; */
  height: 100vh;

}

  .viewer-container {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden; 
    padding-left: 1px;
  }

  .rightside-info-pop-up {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 4px;
    min-width: 0; /* Add this line to prevent content from overflowing */
    padding: 1px;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
  }
  .behind-grid{
    position: relative;
    /* left: 0px; */
    background-color: white;
    width: 100%;
    height: 100%;
  }

  .progress {
    position: absolute;
    top: 0;
    left: 0;
    background: rgb(255, 255, 255);
    width: 100%;
    opacity: 1;
    height: 25px;
    z-index: 1000;
  }
  
  .info-boxes-container {
    display: flex;
    width: auto;
    height: auto;
    display: inline-block;
  }

  .info-pop-up {
      /* flex-direction: column;  */
      display: inline-block;
      gap: 4px;
      opacity: 0;
    }


    .gene-table {
      position: relative;
      max-height: 350px;
      overflow-y: auto;
      display: block;
      background-color: rgba(153, 217, 234, 1);
      border-radius: 15px;
    }
    .gene-info-div{
      display: flex;
      flex-direction: column;
      height: 150px;
      width: 250px;
      background-color: var(--highlight-color-2-dark-mode);
      border-radius: 16px;
      border-width: 1px;
      border-color: var(--text-color-dark-mode);
      border-style: solid;
      padding: 5px;
    }

    .loading-pdb{
      /* display: flex; */
      flex-direction: column;
      height: 250px;
      width: 250px;
      background-color: white;
      border-radius: 16px;
      border-width: 1px;
      border-color: black;
      border-style: solid;
      padding: 5px;
      display: grid;
      place-items: center; /* Center both vertically and horizontally */
    }

    .pdb{
      display: flex;
      flex-direction: column;
      height: 100%;
      /* overflow-y: scroll; */
      width: 100%;
      /* border-radius: 16px; */
      /* border-width: 1px; */
      /* border-color: black; */
      /* border-style: solid; */
      overflow: hidden;
    }
    .cluster-group-header {
      font-family: 'JetBrains Mono', sans-serif; /* Fallback to sans-serif if custom font fails to load */
      color: var(--text-color-dark-mode);
    }
    .pdb-button {
      /* position: absolute; */
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 5px 10px;
      border-radius: 50px; /* Gives the oval shape */
      background-color: var(--highlight-color-2-dark-mode);
      color: var(--text-color-dark-mode); /* Text color */
      cursor: pointer;
      user-select: none;
      border: none;
      outline: none;
      font-size: 8px;
      font-weight: bold;
      transition: background-color 0.3s ease, transform 0.3s ease; /* Smooth transition for hover effects */
    }
    
    .exit-button {
      width: 20px; /* Size of the circle */
      height: 20px; /* Size of the circle */
      background-color: #FF6347; /* Background color */
      border-radius: 50%; /* Makes it a circle */
      color: white; /* Text color */
      display: flex; /* Aligns the child elements (like text) */
      align-items: center; /* Center the content vertically */
      justify-content: center; /* Center the content horizontally */
      font-weight: bold; /* Make the 'X' bold */
      cursor: pointer; /* Changes the cursor to a pointer to indicate it's clickable */
    }
    .cluster-group-info {
      display: flex;
      flex-direction: column;
      align-self: flex-start; /* Override any stretch behavior from parent */
      max-height: 250px; /* Maximum height before scrolling */
      overflow-y: auto; /* Enable vertical scrolling when content overflows */
      /* width: 250px;  */
      width: 100%;
      background-color: var(--highlight-color-2-dark-mode);
      border-radius: 16px;
      border: 1px solid var(--text-color-dark-mode);
      padding: 5px;
    }
    .table-cluster {
      font-family: 'JetBrains Mono', sans-serif; /* Fallback to sans-serif if custom font fails to load */
      padding: 5px;  /* Reduce padding as desired */
      line-height: 1;  
      color: var(--text-color-dark-mode)
    }
    .card-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
      border-radius: 16px;
      margin: 0;
      padding: 2px;
      background-color: var(--highlight-color-2-dark-mode);
      color: var(--text-color-dark-mode);
      font-family: 'JetBrains Mono', sans-serif; /* Fallback to sans-serif if custom font fails to load */
    }
    @font-face {
      font-family: 'JetBrains Mono';
      src: url('/public/assets/fonts/JetBrainsMono-SemiBold.woff2') format('woff2');
      font-weight: normal;
      font-style: normal;
    }

    .card-body {
      padding: 2px;
    }
    .card-body p {
      display: -webkit-box;
      -webkit-line-clamp: 4; /* Clamp to 3 lines */
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      word-wrap: break-word; /* Maintain word wrapping for long words */
      margin: 0;
      padding: 0;
      font-size: 0.7rem;
      color: var(--text-color-dark-mode);
      font-family: 'JetBrains Mono', sans-serif; /* Fallback to sans-serif if custom font fails to load */
    }
    

    .card-footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
      border-radius: 16px;
      margin: 0;
      padding: 2px;
      background-color: var(--highlight-color-2-dark-mode);
      color: var(--text-color-dark-mode);
      font-family: 'JetBrains Mono', sans-serif; /* Fallback to sans-serif if custom font fails to load */
    }

    /* CSS for sticky header */
    .gene-table thead th {
      position: sticky;
      top: 0;
      background-color: rgba(153, 217, 234, 1);
      z-index: 1; /* Ensure header stays above body content */
    }

  .back-drop {
    position: relative;
    top: 0;
    right: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    border-radius: 16px;
    /* background-color: pink;
    overflow: hidden; 
    border: 3px solid white;
    box-shadow: inset 0 0 0 1px #c2c2c2, 0 5px 5px rgba(0, 0, 0, 0.19), 0 5px 5px rgba(0, 0, 0, 0.19); */
    /* z-index: 0; */
  }
  .genome-viewer {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    border-radius: 16px; /* Rounded corners */
    z-index: 100;
  }

  .interactive-layer {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    z-index: 1000;

  }

  .drag-select-box {
    position: relative;
    /* pointer-events: none; */
    /* border: 2px dashed blue; */
    /* border-radius: 16px; */
  }

 .tooltip{
  padding: 0px;
 }


  .cookie-trail-item:first-child {
    /* Additional styling for the first item if necessary */
}

.cookie-trail-item:nth-child(2) {
    margin-left: auto;
    margin-right: auto;
}

.cookie-trail-item:last-child {
    /* Additional styling for the last item if necessary */
}
  

  
  
  
  /* .notifications-container {
    position: absolute;
    bottom: 0px;
    display: flex;
    width: 100vw;
    height: 100vh;
    flex-direction: column;
  } */
  .notifications-container ul {
    position: fixed;
    bottom: 8px;
    right: 8px;
    display: flex;
    flex-direction: column;
    list-style: none;
    justify-content: flex-end;
  }
  
  .notifications-container li {
    width: 300px;
    /* height: 50px; */
    /* Updated background to use a gradient */
    background: linear-gradient(to right, rgba(0, 255, 208, 0.2), rgba(170, 0, 255, 0.2)); 
    margin: 10px;
    flex: 0 0 50px;
    padding: 10px;
    position: relative;
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.3); /* Slightly visible border */

    /* Backdrop Filter for Glass Effect */
    backdrop-filter: blur(10px);

    /* Optional: Box shadow for a more "lifted" look */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

    /* For better cross-browser compatibility */
    -webkit-backdrop-filter: blur(10px);
}

/* Optional: Adding a hover effect with a slightly different gradient */
li:hover {
    background: linear-gradient(to right, rgba(255, 255, 255, 0.3), rgba(235, 233, 233, 0.3)); /* Slightly less transparent gradient on hover */
}

