/* HomeInfoPanels.css */

.info-panels-container {
    display: flex;
    flex-direction: column;
    gap: 1rem; /* adjust the space between boxes */
  }
  
  .info-panel-home {
    border: 1px solid gray; /* change as per the border style you need */
    padding: 1rem;
    border-radius: 8px; /* adjust for rounded corners if needed */
    height: 135px;
    display: flex;
    flex-direction: column;

  }
  
  .info-panel-home h2 {
    margin-top: 0;
    font-family: 'Geist Mono';
}
  
  .info-panel-home p {
    font-family: 'Geist Variable';
    font-size: 14px;

  }
  