::selection {
    color: black; /* This changes the text color upon selection, optional */
    background: transparent; /* This removes the highlight color */
  }

.sequence-context-menu {
  display: flex;
  flex-direction: column;
  height: auto;
  width: 250px;
  background-color: rgba(245, 245, 220, 1);
  border-radius: 16px;
  border-width: 2px;
  border-color: black;
  border-style: solid;
  padding: 5px;
}


