.sticky-header {
  height: 10vh; 
  position: sticky; 
  top: 0;
  backdrop-filter: blur(10px);
  display: flex;
  justify-content: space-between;
  padding: 20px 20px;
  z-index: 1000;
  align-items: center;
  background-color: #F1EFED;
  }
  
  .header-left, .header-right {
    font-size: 15px;
    padding: 10px 20px; 
    border-radius: 50px;
    height: 30px;
    border: 1px solid #5857FF; 
    display: flex;
    align-items: center; 
  }

  .header-buttons {
    display: flex; 
    align-items: center; 
    gap: 20px;
  }
  
  .user-icon {
    width: 40px; 
    height: 40px; 
    border-radius: 50%; 
    margin-right: 10px; 
  }
  
  .user-info {
    display: flex;
    align-items: center; 
    gap: 10px;
  }

  .header-button-panel {
    height: 30px;
    border-radius: 50px;
    border: 1px solid #5857FF; 
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    gap: 30px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .spinner {
    border: 4px solid rgba(255, 255, 255, 0.3); /* Light grey */
    border-top: 4px solid #5857FF; /* Blue */
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 1s linear infinite;
    margin-right: 50px;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  