
:root {
    --base-unit: max(.5px, 1/1800 * 100vw);
    --border-radius: calc(var(--base-unit) * 32);
}

.database {
    /* width: 40%; */
    width: 20vw;
    height: 100%;
    /* border-radius: var(--border-radius);  */
    padding: 8px;
    overflow: auto; /* Enables scrolling when content overflows */
}

.database-header {
    font-size: 12px;
    color: rgb(210, 211, 224);
    display: flex;
    flex-direction: row;
    gap: 8px;
}

.database-info{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}
  
.create-header {
    text-align: left;
    width: 100%; /* Assuming your rows are also 80% width */
    padding-left: 10px; /* Adjust based on your .table-row padding or alignment needs */
    font-weight: bold; /* Optional: to make it stand out as a header */
    font-size: 2rem;
    margin-bottom: 10px; /* Optional: adds some space between the header and the first row */
  }

  .database-item {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border-radius: var(--border-radius);
    max-height: calc(max(0.5px, 1/1800 * 100vw) * 64);
    width: 100%; /* Adjust as necessary */
    text-align: center; /* Ensure text is centered */
    overflow: hidden; /* Hide overflowing content */
    text-overflow: ellipsis; /* Add ellipsis when content overflows */
    white-space: nowrap; /* Prevent line breaks */
    cursor: pointer; /* Indicate hoverability */
  }
  .database-item .dbtext {
    width: 90%;
    font-size: 1rem;
  }

.tables {
    display: flex;
    flex-direction: column;
    align-items: left;
    /* background-color: #f7f7f8; */
    width: 50vw;
    height: 100%; /* Fixed height; adjust as needed */
    overflow: auto; /* Enables scrolling when content overflows */
    padding: 10px; /* Optional: Adds some space inside the div */
    /* border-radius: var(--border-radius, 5px); */
}

.table-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    border-radius: var(--border-radius); /* Apply the border-radius */
    padding: 8px;
    /* min-height: calc(max(0.5px,1/1800 * 100vw)*64); */
    width: 100%; /* Adjust as necessary */
}

.rounded-start, .rounded-end {
    flex: 1; /* Ensure they take equal width */
    text-align: center; /* Center the text */
    font-size: .8rem;
}

.selection-settings-panel {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;
    width: 100%;
    height: 100%;
    padding: 10px;
  }
  
  @media (max-width: 600px) {
    .selection-settings-panel {
      flex-direction: column;
    }
  }
.settings-buttons-div{
    display: flex;
    flex-direction: column;
    width: 200px;
    
}
.settings-buttons-div span{
    font-size: 12px;
    /* color: rgb(133, 134, 153); */
}

.ViewToggle {
    display: flex;
    /* min-height: calc(max(0.5px,1/1800 * 100vw)*64); */
    /* background-color: rgb(133, 134, 153); */
    border-radius: var(--border-radius); /* Applied to ensure the pill shape */
    overflow: hidden; /* Keeps child backgrounds within the border-radius */
    max-height: calc(max(0.5px, 1/1800 * 100vw) * 64);

}

.control-panel-container {
    border-bottom: 1px solid rgba(39, 41, 56, 1);
    height: auto; /* Changed from a fixed height to auto */
    display: flex;
    /* These two properties will make the children wrap as needed */
    flex-wrap: wrap;
    justify-content: flex-start; /* Adjust as needed - this aligns children to the start */
    align-items: center; /* Centers items vertically, adjust as needed */
    gap: 10px; /* Optional: Adds a gap between the child elements for better spacing */
    /* padding: 10px; */
  }

  .view-button-div {
    height: 40px;
    border-radius: 6px;
    box-shadow: 0 0 0 1px hsla(0, 0%, 100%, 0.14);
    width: 100px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 3px;
    padding: 3px;
  }
  .view-button {
    height: 100%;
    width: 50%;
    display: flex;
    border-radius: 3px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: gray;
  }
 .function-button-div{
  height: 40px;
  border-radius: 6px;
  box-shadow: 0 0 0 1px hsla(0, 0%, 100%, 0.14);
  width: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 3px;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
 }

.ViewToggle div {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.3s; /* Smooth color transition */
}

.LeftHalf {
    border-top-left-radius: var(--border-radius);
    border-bottom-left-radius: var(--border-radius);
}

.RightHalf {
    border-top-right-radius: var(--border-radius);
    border-bottom-right-radius: var(--border-radius);
}

.ViewerSettingsButton {
    display: flex;
    /* min-height: calc(max(0.5px,1/1800 * 100vw)*64); */
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: var(--border-radius); /* Applied to ensure the pill shape */
    overflow: hidden; /* Keeps child backgrounds within the border-radius */
    align-items: center;
    text-align: center; /* Ensure text is centered */
    justify-content: center; /* Center children horizontally */
    margin-top: 2px; /* Add margin-top to create the gap */
    max-height: calc(max(0.5px, 1/1800 * 100vw) * 64);

  }
  

  