
@font-face {
  font-family: 'Aeonik';
  src: url('/public/assets/fonts/AeonikTRIAL-Regular.otf') format('opentype');
}

body {
  font-family: 'Aeonik', sans-serif; /* Fallback to sans-serif if custom font fails */
  background-color: white;
  /* overflow: hidden; */
}
body {
  -webkit-user-select: none;  
  -moz-user-select: none;    
  -ms-user-select: none;     
  user-select: none;         
}
.page-container {
  height: 100vh; /* 300 units for 3 views */
}

.view {
  height: 90vh; /* 100 units for each view */
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative; /* Added to position children absolutely inside it */
}

.left-side-home {
  width: 50vw;
  height: 90vh;
  position: absolute;
  display: flex;
  flex-direction: column;
  left: 0;
  padding: 20px; /* Optional for visual spacing */
}
.right-side-home {
  width: 40vw;
  position: absolute;
  display: flex;
  flex-direction: column; 
  align-items: flex-end; 
  gap: 10px;
  right: 0;
  padding: 20px; /* Optional for visual spacing */
  top: 5vh; /* Adjust this as needed to position your container */
}

.home-info-box{
  height: 150px;
  width: 280px;
  border: 1px solid #5857FF;
  border-radius: 10px;
}
.view.view-one {
  flex-direction: column; /* Stack children vertically */
  position: relative; /* Ensure relative positioning for absolute child elements */

}

.text-above span{
  display: flex;  
  font-size: 128px;
  font-family: 'Geist Variable';
  color: black;
}
.text-middle span{
  display: flex;  
  font-size: 72px;
  font-family: 'Geist Variable';
  color: #5857FF;
  line-height: 1;
}
.text-bottom {
  display: flex;
  flex-direction: column;
  /* set the height to fill the bottom */
  flex-grow: 1;
  justify-content: flex-end;
  gap: 10px;
}
.get-started {
  height: 45px;
  background-color: #5857FF;
  border-radius: 50px;
  width: 150px;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.get-started-span {
  color: white;
  font-size: 100%;
}

.text-bottom-span{
  font-size: 50px;
  font-family: 'Geist Variable';
  color: black;
  line-height: 1;
}

.text-below {
  position: absolute; /* Position the text absolutely */
  bottom: 10px; /* Distance from the bottom */
  width: 100%; /* Take the full width */
  text-align: center; /* Center the text horizontally */
  font-size: 1.2em; /* Adjust font size as needed */
}


.swirl-animation {
  position: absolute; /* Position the SVG absolutely */
  left: 0; /* Align it to the left edge of the screen */
  z-index: -1;
}







.view.view-two {
  flex-direction: column;
  position: relative;
  overflow: hidden; /* Hide overflow for full background coverage */
}

.spline-two-wrapper {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1; /* Behind other content */
}

.view-two-header {
  font-size: 5rem;
  line-height: 1; /* Adjusted line height */
  position: absolute;
  top: 10px;
  text-align: center;
  max-width: 90vw;
  z-index: 2; /* Above the Spline background */
}

.view-two-paragraph {
  position: absolute;
  top: 50%; /* Middle of the screen */
  right: 5%; /* Adjust left position */
  transform: translateY(-50%);
  text-align: left;
  font-size: 1.5em;
  max-width: 40%;
  z-index: 2;
}

.view.view-two .motion-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  z-index: 2;
}

/* Responsive adjustments for different screen sizes */
@media (max-width: 768px) {
  .view-two-header {
    font-size: 2rem;
  }

  .view-two-paragraph {
    top: 30%; 
    font-size: 0.8rem;
    max-width: 60%;
    background: rgba(255, 255, 255, 0.7);
    backdrop-filter: blur(5px);
    border-radius: 10px;
    padding: 20px;
  }
}

/* view three */
.view3 {
  height: 100vh; /* 100 units for each view */

}
.right-side {
  width: 50%;
  float: right;  /* Keeps the div on the right side */
  background-color: lightblue;  /* Optional for visualization */
  padding: 20px;  /* Optional for visual spacing */
}

.text-div {
  margin-bottom: 10px;  /* Adds spacing between the text and button */
}


.children-dev {
  width: 50%;
  margin-left: auto; /* Aligns the div to the right */
  padding: 20px; /* Adjust as needed */
  box-sizing: border-box; /* Includes padding in the width */
  z-index: 100;
}

.children-dev p {
  font-size: 1.5em;
}
@media (max-width: 768px) {
  .view-two-header {
    font-size: 2rem;
  }

  .children-dev p {
    font-size: 0.8rem;
    background: rgba(255, 255, 255, 0.7);
    backdrop-filter: blur(5px);
    border-radius: 10px;
    padding: 20px;
  }
}


.learn-more-button {
  position: relative;
  overflow: hidden;
  background-color: white;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 40px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px; /* Example fixed width */
  white-space: nowrap; /* Keeps text in a single line */
}

.launch-button {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  position: relative;
  overflow: hidden;
  background-color: white;
  padding: 10px 20px;
  border-radius: 60px;
  border: 1px solid #5857FF; 
  height: 30px;
  white-space: nowrap; /* Keeps text in a single line */
}

.content-wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
  
}

.learn-more-button .circle {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: black;
  margin-right: 10px;
}

.learn-more-button .learn-more-text {
  font-family: 'Aeonik', sans-serif;
  font-size: 1rem;
  margin: 0; /* Remove default margins */

}
