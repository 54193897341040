
.left-side{
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.pdb-container{
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 4px;
  height: 100%;
}
.both-sides{
  display: flex;
  flex-direction: row;
  gap: 4px;
}

.button-panel {
  display: grid;
  flex-direction: column;
  grid-template-columns: repeat(2, 1fr); /* Keeps the 2xN grid */
  grid-gap: 10px;
  justify-items: start;
  align-items: start;
  border-radius: 16px;
  margin: 0px;
  gap: 10px;
  padding: 5px;
  width: 100%;
  /* display: inline-block; */

  /* border: 1px solid var(--text-color-dark-mode);  */

}

.function-grid-button {
  display: flex;
  align-items: center;
  justify-content: left;
  padding: 8px;
  background-color: lightgrey;
  border-radius: 16px;
  cursor: pointer;
  width: 100%;
  font-size: 0.7rem;
}

.function-button-container {
  display: flex;                 /* Enable Flexbox */
  justify-content: space-between; /* Space between items */
  align-items: center;           /* Align items vertically in the center */
}

.circle {
  width: 8px;        
  height: 8px;       
  border-radius: 50%;  
  display: flex;      
  justify-content: center;
  align-items: center;
  background-color: #5857FF;
  line-height: 20px; /* Same as height */

}

.plus {
  font-size: 20px;
  color: black;
  display: block;
  margin: 0;
  padding: 0;
  line-height: 20px; /* Adding this might help */

}

.functions {
  margin-left: 10px;
}

.function-grid-button-anchor {
  display: flex;
  align-items: center;
  justify-content: left;
  padding: 8px;
  color: var(--text-color-dark-mode);
  /* background-color: rgba(98, 56, 216, 1); */
  background-color: var(--highlight-color-2-dark-mode);
  border-radius: 16px;
  cursor: pointer;
  width: 100%;
  font-size: 0.7rem;
}

.function-button {
  display: flex;
  align-items: center;
  justify-content: left;
  padding: 10px; /* Adjusted padding for better look */
  border-radius: 16px;
  border: 1px solid black; /* Simplified border declaration */
  cursor: pointer;
  /* width: 100%;  */
}

/* .rectangular-oval-button {
    display: flex;
    align-items: center;
    justify-content: left;
    padding: 5px 2px;
    margin: 0px;
    width: 50px;
    border-radius: 16px;
    border-width: 1px;
    border-color: black;
    border-style: solid;
    cursor: pointer;
  } */
 
  .button-text {
    font-weight: bold;
    font-size: .8rem;
    padding-left: 10;
  }
  
  .button-icon {
    height: 20px; /* adjust size as needed */
    margin-left: 10px; /* space between text and icon */
  }

.material-symbols-outlined {
  font-variation-settings:
  'FILL' 0,
  'wght' 400,
  'GRAD' 0,
  'opsz' 24
}
