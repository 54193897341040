

.table-button {
    /* Position button to the right */
    float: right;
    /* Set padding */
    padding: 8px;
}

th, td {
    font-size: .8rem;
    border-bottom: 1px solid #000000;
    padding: 8px;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 150px;
}


.table-button {
    /* Add styles for your buttons here if needed */
    /* For example, you might want to set a minimum width and height */
    min-width: 100px;
    min-height: 40px;
}
  
.table-button {
    /* Align the button to the right */
    float: right;
    /* Adjust margin for spacing */
    margin-top: 10px;
}
  
.holds-table {
    max-height: '20vh';
    border-radius: '16px';
    padding-bottom: '8px';
    padding-left: '8px';
    padding-right: '8px';
    position: absolute;
    color: white;
    /* bottom: 10px;
    right: 10px; */
    /* top: 260px; */
    /* left: 460px; */
    /* max-height: 20vh; */
    pointer-events: auto;
    border-radius: 16px; 
    overflow: auto;
    /* Glass-like effect styles */
    background-color: rgba(56, 56, 56, 0.7); /* Semi-transparent white background */
    backdrop-filter: blur(5px); /* Blurred background for frosted glass look */
    -webkit-backdrop-filter: blur(5px); /* For older WebKit browsers */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15), 0 1px 3px rgba(0, 0, 0, 0.12); /* Light shadows for depth */
}




.table-button {
    /* Position button to the right */
    float: right;
    /* Set padding */
    padding: 8px;
}



.fasta-input {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    gap: 6px;
}

.fasta-input textarea {
    width: 90%; /* Makes the input text box larger */
    height: 70%;
    padding: 14px;
    font-size: .8rem;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.fasta-input button {
    font-size: 1rem; /* Adjust as needed */
    border: none;
    border-radius: 4px;
    cursor: pointer;
    background-color: #ffffff;
    /* color: black; */
}

/* Adding a hover effect for the button */
.fasta-input button:hover {
    background-color: #c4c4c4;
}
