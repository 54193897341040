.alignmentContainer {
    font-family: 'JetBrains mono', Courier, monospace;
    white-space: pre; /* Preserve whitespace characters like space and newline */
    background: #f4f4f4;
    padding: 10px;
    border-radius: 8px;
    border: 1px solid #ccc;
    font-size: 10px;

    max-height: 250px; /* Maximum height before scrolling */
    overflow-x: auto; /* Enable vertical scrolling when content overflows */
    overflow-y: auto; /* Enable vertical scrolling when content overflows */
    /* width: 600px;  */
    width: 100%; /* Full width */
    background-color: beige;
    border-radius: 16px;
    border: 2px solid black; /* Combined border properties */
    padding: 5px;
    overflow-x: auto; /* Enable horizontal scrolling */

  }
  
  .sequenceLine {
    display: flex; /* Use Flexbox */
    align-items: center; /* Vertically center the flex items */
    margin-bottom: 5px;
  }

  
  .sequenceName {
    flex-shrink: 0; /* Prevent the name from shrinking */
    margin-right: 10px; /* Add some space between the name and the sequence */
    max-width: 300px;
    overflow: auto; /* Enable scrolling for overflow */
    white-space: nowrap; /* Keep the text in a single line */
    display: inline-block; /* Necessary for width and overflow to work on span */
    position: -webkit-sticky; /* For Safari */
    position: sticky;
    padding-top: 2px;
    padding-bottom: 2px;
    height: auto;
    left: 0; /* Stick to the left side */
    background: beige; /* Match the container's background */
  }
  
  .sequence {
    color: #333;
  }

  /* Additional styles for colored backgrounds */
.residue {
    display: inline-flex; /* Use inline-flex to keep sequence together and aligned */
    align-items: center; /* Center align the residues if needed */
    /* display: inline-block; */
    padding: 1px;
    margin: 0px;
    /* border-radius: 3px; */
  }
  .polar { background-color: #A0A0FF; }
  .nonPolar { background-color: #0F0; }
  .acidic { background-color: #F00; }
  .basic { background-color: #FF0; }
  .special { background-color: #FF9C42; } /* For cysteine and proline */